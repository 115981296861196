import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'

class Hero extends React.Component {

  render() { 
    const data = this.props.data;

    console.log('Hero data: ', data);

    return (
      <div className={!!data.arealink?"hero":"hero hero--nolinkarea"}>
        {!!data.img &&
          <Img fluid={data.img} alt={data.title} className="hero__img" />
        }  
        <div className="hero__text">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {data.heading ? (
                  <h1 className="hero__title" dangerouslySetInnerHTML={{ __html: data.title }}>
                  </h1>
                ) : (
                  <div className="hero__title" dangerouslySetInnerHTML={{ __html: data.title }}>
                  </div>
                )}
                {!!data.subtitle &&
                   <div className="hero__subject hidden-xs">{data.subtitle}</div>
                }
              </div>
            </div>
          </div>
        </div>

        {!!data.arealink && data.arealink &&
          <div className="hero__link">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4 offset-md-1">
                  <div className="hero__btn">
                    <img src="/images/contents/icona_emergenza.png" alt="Hai una emergenza?" className="" />
                    <Link to="/emergenza/" title="Hai una emergenza?" className="btn-link btn-link--block">Hai una emergenza?</Link>
                    <div className="d-none d-sm-block"><em>Pronto soccorso h24</em></div>
                  </div>
                  <div className="hero__btn d-md-none d-sm-block mt-4">
                    <Link to="/prenota-visita/" title="Prenota una visita" className="btn-link btn-link--block">Prenota una visita</Link>
                  </div>
                </div>
                <div className="col-6 col-md-4 offset-md-2 d-none d-md-block">
                  <div className="hero__btn">
                    <img src="/images/contents/icona_stethoscope.png" alt="Prenota una visita" className="" />
                    <Link to="/prenota-visita/" title="Prenota una visita" className="btn-link btn-link--block">Prenota una visita</Link>
                    <div className="d-none d-sm-block"><em>Informazioni e prenotazioni</em></div>
                  </div>
                </div>
                <div className="clearfix d-block d-sm-none"></div>
              </div>
            </div>
          </div>
        } 


      </div>
    )
  }
}

export default Hero